var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$apollo.loading)?_c('Loading'):_c('div',{staticClass:"background-2 px-4 px-sm-0 component-edit_card"},[_c('v-card-actions',{staticClass:"py-2 px-0 px-sm-4 btn-actions pa-2 justify-end flex-wrap",staticStyle:{"gap":"16px 8px"}},[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":_vm.$apollo.loading || _vm.saving,"title":_vm.$t('roles_edit.deleteTitle')},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-flex'),_c('v-btn',{staticStyle:{"border":"2px solid var(--v-primary-base)"},attrs:{"height":"32","depressed":"","tile":"","outlined":"","title":_vm.$t('roles_edit.undoTitle'),"disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"16"}},[_vm._v("undo")]),_vm._v(" "+_vm._s(_vm.$t('roles_edit.undoBtn'))+" ")],1),_c('v-btn',{staticClass:"px-2 ml-0",staticStyle:{"color":"white"},attrs:{"height":"32","depressed":"","tile":"","color":"primary","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('roles_edit.saveBtn'))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"16"}},[_vm._v("save")])],1)],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"calc(100% - 52px)"}},[_c('Fields',{attrs:{"schema":{
        name: {
          type: 'string',
          label: _vm.$t('roles_edit.schema.name.label'),
          description: _vm.$t('roles_edit.schema.name.description'),
          newDesign: true
        }
      },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }